
body{
  /* background:#000; */
}
.App {
  text-align: center;
  
}

.img{
  width:100vw;
  margin:0;
  padding:0;
}

.copy-btn{
  width:5rem;
  height:5rem;
  background:rgba(0,0,0,0);
  z-index: 1000;
  border: 0;
  position: fixed;
  right:10px;
  top:30vh;
}
.copy-btn>img{
  width:3rem;
  height:3rem;
  animation: heartbeat 1.2s linear 1s infinite;
}
.copy-btn>button{
  width:100%;
  height:100%;
  padding:0.5rem 2.5rem;
  position: absolute;
  z-index:10;
  border:0;
  background:rgba(0,0,0,0);
}

@keyframes heartbeat {
	0% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
	14% {
        -webkit-transform: scale(1.3);
        transform: scale(1.3)
    }
    28% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
    42% {
        -webkit-transform: scale(1.3);
        transform: scale(1.3)
    }
    70% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}